<div class="breadcrumb-shadow">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <!--<a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>-->
        <span *ngIf="!last" [routerLink]="breadcrumb.url" style="margin-left:10px">{{ breadcrumb.label }}</span>
        <span *ngIf="last">{{ breadcrumb.label }}</span>
      </li>
    </ol>
  </nav>
</div>