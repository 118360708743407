import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig } from '../../enviroment-config';
import { ReadApiService } from './read-api.service';
/**
 * Classe astratta di servizio per effettuare le chiamate al backend applicativo
 */
export abstract class CrudApiService<
  T extends { id: number }
> extends ReadApiService<T> {
  constructor(basePath: string, http: HttpClient, config: EnvironmentConfig) {
    super(basePath, http, config);
  }

  deleteItem(id: number) {
    const path = this.getPath(`${this.basePath}/${id}`);

    return this.http.delete(path);
  }

  update(item: T) {
    const path = this.getPath(`${this.basePath}/${item.id}`);

    return this.http.put(path, item);
  }

  create(item: T) {
    const path = this.getPath(`${this.basePath}`);

    return this.http.post(path, item);
  }

  orderPoints(items:any) {
    const path = this.getPath(`${this.basePath}`);
    return this.http.post(path, items);
  }

  
  updateSmartBin(item: any) {
    const path = this.getPath(`${this.basePath}`);
    return this.http.post(path, item);
  }

}
