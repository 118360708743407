import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Models, Modules } from 'src/app/data/model/permission';
import { it, Menu } from '../../navigation-menu/navigation-menu.component';
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private breadcrumbsSubject = new BehaviorSubject<Array<{ label: string; url: string }>>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  // Menu str;ucture
  menuData: Menu[] = it;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.createBreadcrumbs();
      });
  }

  private createBreadcrumbs() {
    const breadcrumbs: Array<{ label: string; url: string }> = [];
    const currentRoute = this.activatedRoute.root;

    // Traverse the activated route tree and build breadcrumbs
    this.traverseRoutes(currentRoute, '', breadcrumbs);

    // Update the breadcrumb stream
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  private traverseRoutes(route: ActivatedRoute, url: string, breadcrumbs: Array<{ label: string; url: string }>) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
        const labels = this.getLabelsForRoute(url);
        
        if (labels.parent) {
          breadcrumbs.push({ label: labels.parent, url });
        }
        if (labels.sub_parent) {
          breadcrumbs.push({ label: labels.sub_parent, url });
        }
        if (labels.item) {
          breadcrumbs.push({ label: labels.item, url });
        }
      }

      // Recurse for nested routes
      this.traverseRoutes(child, url, breadcrumbs);
    }
  }

  private getLabelsForRoute(url: string): { parent: string | null; sub_parent?:string | null; item: string | null } {
    for (const menu of this.menuData) {
      for (const item of menu.items) {
        if (this.compareLinks(item.link, url)) {
          return { parent: menu.label, item: item.label };
        }
        if (item.sub_menu) {
          for (const subItem of item.sub_menu.items) {
            if (this.compareLinks(subItem.link, url)) {
              return { parent: menu.label, sub_parent: item.label, item: subItem.label };
            }
          }
        }
      }
    }
    return { parent: null, item: null };
  }

  private compareLinks(link: string[] | undefined, url: string): boolean {
    if (!link) {
      return false;
    }
    const linkStr = link.join('/');
    return linkStr === url;
  }
}