export const environment = {
  production: true,
  apiUrl: 'https://ecocentri.servizi.mquadro.net/api/v1',
};

export const service_order = {
  production: true,
  defaultGridColumns: ['ods_id', 'extradata', 'start_date', 'end_date', 'ts_ods_start', 'ts_ods_end', 'assignedDriver', 'assignedLicensePlate', 'status', 'duration'],// ['ods_id', 'description', 'start_date', 'end_date', 'ts_ods_start', 'ts_ods_end', 'assignedLicensePlate', 'status', 'route','group', 'operations'],
  gridColumns: '${gridColumns}'
};


export const mapURL = {
  production: true,
  defaultMapUrl: 'https://mapservice.mquadro.net',
  mapUrl: '${mapURL}'
};
