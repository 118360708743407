import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServiceOrder } from 'src/app/data/model/service-order';
import { ServiceOrderApiService } from '../../api/services/service-order-api.service';

@Component({
  selector: 'app-duplicate-orders',
  templateUrl: './duplicate-orders.component.html',
  styleUrls: ['./duplicate-orders.component.scss']
})
export class DuplicateOrdersComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ServiceOrder,
    private duplicateservice: ServiceOrderApiService,
    private router: Router) { }

  get params(): any {
    return this.data;
  }
  duplicateOrder(data: ServiceOrder): void {
    data['duplicate']=true;
    this.duplicateservice.create(data).subscribe();
    this.refreshPage();
  }
  
  refreshPage(){
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/currentUrl',{skipLocationChange:true}).then(()=>{
      this.router.navigate([currentUrl]).then(()=>{
      });
    });
  }
}

export type SendOrdersParams = {
  data: any;
};
