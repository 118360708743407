import { HttpClient, HttpParams } from '@angular/common/http';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { EnvironmentConfig } from '../../enviroment-config';
import { BaseService } from './base.service';
/**
 * Classe astratta di servizio per effettuare le chiamate al backend applicativo
 */
export abstract class ReadApiService<
  T extends { id: number }
> extends BaseService {
  constructor(
    protected basePath: string,
    protected http: HttpClient,
    config: EnvironmentConfig
  ) {
    super(config);
  }

  load(
    page: Pageable,
    query: unknown = null
  ): Observable<PagedResponseList<T>> {
    const params = this.convertToHttpParams(query)
      .set('limit', page.pageSize)
      .set('offset', page.page * page.pageSize);

    const path = this.getPath(`${this.basePath}`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }

  options(){
    const path = this.getPath(`${this.basePath}`);
    return this.http.options<PagedResponseList<T>>(path);
  }
  
  getAll() {
    const path = this.getPath(`${this.basePath}`);
    return this.http.get<PagedResponseList<T>>(path);
  }

  public getSorted(
    sort: Sort,
    page: Pageable,
    query: unknown = null
  ) {
    const params = this.convertToHttpParams(query)
      .set('sort', sort.active)
      .set('order', sort.direction)
      .set('limit', page.pageSize)
      .set('offset', page.page * page.pageSize);
    const path = this.getPath(`${this.basePath}`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }

  getFiltered(id: number, query: unknown = null) {
    const params = this.convertToHttpParams(query)
      .set('data_collector', String(id))
    const path = this.getPath(`${this.basePath}`);
    return this.http.get<PagedResponseList<T>>(path, {params});
  }
  getVisibleOnMap(query: unknown = null) {
    const params = this.convertToHttpParams(query)
      .set('visible_on_map', Boolean(true))
    const path = this.getPath(`${this.basePath}`);
    return this.http.get<PagedResponseList<T>>(path, {params});
  }
  getGeoJsonFiltered(id: number, query: unknown = null) {
    const params = this.convertToHttpParams(query)
      .set('data_collector', String(id))
    const path = this.getPath(`${this.basePath}/geo-gps`);
    return this.http.get<PagedResponseList<T>>(path, {params});
  }

  getManyFiltered(id: number[], query: unknown = null) {
    const params = this.convertToHttpParams(query)
      .set('data_collector', String(id))
    if (id.length === 0){
      console.log("Nessun mezzo selezionato")
      return null
    }
    else{
      const path = this.getPath(`${this.basePath}`);
      return this.http.get<PagedResponseList<T>>(path, {params});
    }
  }

  get(id: number) {
    const path = this.getPath(`${this.basePath}/${id}`);
    return this.http.get<T>(path);
  }
  getFilteredGPS(
    licenseplate:string,
    created_at:any,
    query: unknown = null
  ){
    const params = this.convertToHttpParams(query)
      .set('licenseplate', licenseplate)
      .set('created_at', created_at)
    const path = this.getPath(`${this.basePath}`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }
  getFilteredGeoGPS(
    licenseplate:string,
    created_at:any,
    query: unknown = null
  ){
    const params = this.convertToHttpParams(query)
      .set('licenseplate', licenseplate)
      .set('created_at', created_at)
    const path = this.getPath(`${this.basePath}/geo-gps`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }
  getFilteredCustTax(
    tax_code:string | any,
    query: unknown = null
  ){
    const params = this.convertToHttpParams(query)
      .set('tax_code', tax_code)
    const path = this.getPath(`${this.basePath}`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }

  getFilteredCustVat(
    vat_num:string | any,
    query: unknown = null
  ){
    const params = this.convertToHttpParams(query)
      .set('vat_num', vat_num)
    const path = this.getPath(`${this.basePath}`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }
  getEventsGPS(
    licenseplate:string,
    query: unknown = null
  ){
    const params = this.convertToHttpParams(query)
      .set('licenseplate', licenseplate)
      //.set('created_at', created_at)
    const path = this.getPath(`${this.basePath}`)//?&sort=${sort.active}&order=${sort.direction}&limit=${page}&offset=0`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }

  getWithFilter(
    query: unknown = null
  ): Observable<PagedResponseList<T>> {
    const params = this.convertToHttpParams(query)
      //.set('limit', page.pageSize)
      //.set('offset', page.page * page.pageSize);

    const path = this.getPath(`${this.basePath}`);
    return this.http.get<PagedResponseList<T>>(path, {
      params,
    });
  }
}

export type Pageable = {
  page: number;
  pageSize: number;
};

export type Sortable = {
  active:string ;
  direction: string;
};

export type PagedResponseList<T> = {
  count: number;
  results: T[];
};
