<div>
    <h2 mat-dialog-title></h2>
    <mat-dialog-content >
      <p *ngIf="data">Sei sicuro di voler inviare duplicare l'ordine di servizio ?</p>
      <p *ngIf="!data">Nessun dato da duplicare</p>
    </mat-dialog-content>
    <mat-dialog-actions align="bottom">
      <button mat-button mat-dialog-close>Annulla</button>
      <button
        mat-button
        class=""
        (click)="duplicateOrder(data)"
        [mat-dialog-close]="true"
        cdkFocusInitial
        color="warning"
      >
        Invia
      </button>
    </mat-dialog-actions>
  </div>