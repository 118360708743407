import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { it, Menu } from '../navigation-menu/navigation-menu.component';
import { BreadcrumbsService } from './service/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs-nav',
  templateUrl: './breadcrumbs-nav.component.html',
  styleUrls: ['./breadcrumbs-nav.component.scss']
})
export class BreadcrumbsNavComponent implements OnInit {
  breadcrumbs: Array<{ label: string, url: string }> = [];

  constructor(private breadcrumbService: BreadcrumbsService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbs$.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
    });
  }
}